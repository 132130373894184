exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./statics/fonts/LibreBaskerville-Regular.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./statics/fonts/LibreBaskerville-Regular.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./statics/fonts/LibreBaskerville-Bold.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./statics/fonts/LibreBaskerville-Bold.woff"));

// Module
exports.push([module.id, "*,\n*:before,\n*:after {\n  box-sizing: border-box; }\n\na {\n  text-decoration: none; }\n\ntextarea {\n  width: 100%;\n  border-radius: 5px;\n  background-color: black;\n  border: 1px solid white;\n  color: white;\n  padding: 1rem;\n  line-height: 1.5;\n  font-size: 16px; }\n\nbutton {\n  background-color: white;\n  color: black;\n  font-weight: bold;\n  border-radius: 9999px;\n  line-height: 1.1;\n  padding: 0.625rem 3.125rem;\n  border: none;\n  font-size: 0.875rem;\n  letter-spacing: -0.025rem;\n  cursor: pointer; }\n\nbody {\n  background-color: black;\n  color: white;\n  font-family: 'Helvetica Neue', Arial, sans-serif; }\n\n@font-face {\n  font-family: 'Libre Baskerville';\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: normal; }\n\n@font-face {\n  font-family: 'Libre Baskerville';\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\");\n  font-weight: bold;\n  font-style: normal; }\n\nh1 {\n  font-size: 5.25rem;\n  margin: 0;\n  margin-bottom: 0.5rem;\n  letter-spacing: -0.18125rem;\n  line-height: 0.9; }\n\nh2 {\n  margin: 0;\n  margin-bottom: 0.5rem; }\n\nh3 {\n  font-size: 1.25rem;\n  margin: 0;\n  margin-bottom: 0.5rem;\n  font-family: 'Libre Baskerville', Georgia; }\n", ""]);

