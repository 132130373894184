import React, { useState } from 'react';

import SentimentAnalysis from '../SentimentAnalysis';
import LanguageAnalysis from '../LanguageAnalysis';
import Readability from '../Readability';
import './styles.scss';

import { recogniseLanguage, detectSentiment } from '../../api';

const formatbody = text => ({
  lang_code: 'eng',
  text
});

function App() {
  const [languageMatches, setLanguageMatches] = useState([]);
  const [sentimentMatches, setSentimentMatches] = useState([]);
  const [sentimentAvgScore, setSentimentAvgScore] = useState(0);
  const [text, setText] = useState('');

  const fetchSentimentAnalysis = async text => {
    const { detail_list, value } = await detectSentiment(formatbody(text));
    setSentimentMatches(detail_list);
    setSentimentAvgScore(value);
  };

  const fetchLanguageAnalysis = async text =>
    setLanguageMatches(await recogniseLanguage(formatbody(text)));

  const analyzeText = e => {
    e.preventDefault();
    const text = e.target.text.value;
    setText(text);
    fetchSentimentAnalysis(text);
    fetchLanguageAnalysis(text);
  };

  return (
    <div className="Main">
      <h1>Text and Document Analysis</h1>
      <h3>
        This interface will analyse the text typed and display the results it
        found below.
      </h3>
      <div className="MainSection">
        <form onSubmit={analyzeText} className="MainSection-form">
          <textarea
            name="text"
            // value="This is a good review. This is a bad review. This is neutral."
            rows="15"
          />
          <button type="submit" className="MainSection-button">
            Analyze
          </button>
        </form>
        <Readability text={text} />
      </div>
      <div className="MainSection">
        <SentimentAnalysis
          sentimentMatches={sentimentMatches}
          avg={sentimentAvgScore}
          text={text}
        />
        <LanguageAnalysis
          sentimentMatches={sentimentMatches}
          avg={sentimentAvgScore}
          text={text}
          matches={languageMatches}
        />
      </div>
    </div>
  );
}

export default App;
