import React from 'react';
import './styles.scss';

const positiveColor = '#07a680';
const negativeColor = '#fc351b';

const setColor = value =>
  value < 0 ? negativeColor : value > 0 ? positiveColor : 'white';

const SentimentAnalysis = ({ text, sentimentMatches, avg }) => (
  <div className="Sentiment">
    <h2>Sentiment Analysis</h2>
    <div className="SentimentSummary">
      <p className="SentimentSummary-heading">Sentiment Average</p>
      <h1>{avg}</h1>
    </div>
    <div>
      {sentimentMatches.map(({ index, len, value }, mapIndex) => (
        <span style={{ color: setColor(value) }} className="Sentiment-preview">
          {text.substr(index, len)}
          {sentimentMatches[mapIndex + 1] &&
            text.substring(index + len, sentimentMatches[mapIndex + 1].index)}
        </span>
      ))}
    </div>
    <div className="SentimentSummary">
      {sentimentMatches.lnegth > 0 && <h3>Sentence Summary</h3>}
      <div>
        {sentimentMatches.map(({ index, len, value }) => (
          <p className="SentimentSummary-line">
            <span>{text.substr(index, len)}</span>
            <span style={{ color: setColor(value) }}>{value}</span>
          </p>
        ))}
      </div>
    </div>
  </div>
);

export default SentimentAnalysis;
