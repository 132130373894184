import React from 'react';
import './styles.scss';
import { ReactComponent as Logo } from '../../statics/praekelt-logo.svg';

const Header = () => (
  <header className="Header">
    <Logo className="Header-logo" />
  </header>
);

export default Header;
