import React from 'react';
import './styles.scss';

const languages = {
  eng: 'English',
  afr: 'Afrikaans',
  tsn: 'Tswana',
  ven: 'Venda',
  ssw: 'Swati',
  nso: 'Pedi/Northern Sotho',
  tso: 'Tsonga',
  sot: 'Southern Sotho',
  zul: 'Zulu',
  xho: 'Xhosa',
  nbl: 'Ndebele'
};

const fomatProbability = probability => {
  const percentage = probability * 100;
  return probability === 1 ? (
    <h1>100%</h1>
  ) : (
    <span>{Math.round(percentage)}%</span>
  );
};

const Language = ({ text, matches, avg }) => (
  <div className="Language">
    <h2>Language Analysis</h2>
    <div>
      {!text ? (
        <p className="Language-emptyState">N/A</p>
      ) : (
        <>
          {matches.map(({ probability, label }) => (
            <p className="Language-item">
              <span>{languages[label]}</span>
              {fomatProbability(probability)}
            </p>
          ))}
        </>
      )}
    </div>
  </div>
);

export default Language;
