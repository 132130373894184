import React from 'react';
import './styles.scss';
// eslint-disable-next-line
import { ReactComponent as Logo } from '../../statics/praekelt-logo.svg';

const Footer = () => (
  <footer className="Footer">
    <h2 className="Footer-greeting">Say Hello</h2>
    <div className="FooterItems">
      <div className="FooterItem">
        <h4>Call Us</h4>
        <a href="tel:011-482-8684">011 482 8684</a>
      </div>
      <div className="FooterItem">
        <h4>About Praekelt</h4>
        <a href="https://www.praekelt.com/">Praekelt.com</a>
      </div>
      <div className="FooterItem">
        <h4>Tell me more</h4>
        <a href="https://www.feersum.io/">Feersum Engine</a>
      </div>
    </div>
    <span className="Footer-copyright"> Copyright &copy; 2019 Praekelt</span>
  </footer>
);

export default Footer;
