//MOVE TO ENV VARS
const token = 'f2da2d23-7dcb-4aa9-b3fe-6842fdfcb2d1';
const nluApiUrl = 'https://nlu.playground.feersum.io:443/nlu/v2';
const languageRecogniserUrl = `${nluApiUrl}/language_recognisers/default/retrieve`;
const sentimentDetectorUrl = `${nluApiUrl}/sentiment_detectors/generic/retrieve`;

const headers = new Headers({
  'X-Auth-Token': token,
  'Content-Type': 'application/json',
  Accept: 'application/json'
});

const basicFetch = async (url, body) => {
  const response = await fetch(url, {
    method: 'post',
    headers,
    body: JSON.stringify(body)
  });

  return response
    .json()
    .then(body => body)
    .catch(error => console.error('Error:', error));
};

export const recogniseLanguage = body =>
  basicFetch(languageRecogniserUrl, body);
export const detectSentiment = body => basicFetch(sentimentDetectorUrl, body);
