import React from 'react';
import syllable from 'syllable';
import './styles.scss';

// https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests

const scores = {
  '0': {
    schoolLevel: 'N/A',
    level: 'N/A'
  },
  '10': {
    schoolLevel: 'College Graduate',
    level: 'Very difficult to read. Best understood by university graduates.'
  },
  '20': {
    schoolLevel: 'College Graduate',
    level: 'Very difficult to read. Best understood by university graduates.'
  },
  '30': {
    schoolLevel: 'College Graduate',
    level: 'Very difficult to read. Best understood by university graduates.'
  },
  '40': {
    schoolLevel: 'College',
    level: 'Difficult to read.'
  },
  '50': {
    schoolLevel: 'College',
    level: 'Difficult to read.'
  },
  '60': {
    schoolLevel: '10th to 12th Grade',
    level: 'Fairly difficult to read.'
  },
  '70': {
    schoolLevel: '8th & 9th Grade',
    level: 'Plain English. Easily understood by 13- to 15-year-old students.'
  },
  '80': {
    schoolLevel: '7th Grade',
    level: 'Fairly easy to read.'
  },
  '90': {
    schoolLevel: '6th Grade',
    level: 'Easy to read. Conversational English for consumers.'
  },
  '100': {
    schoolLevel: '5th Grade',
    level:
      'Very easy to read. Easily understood by an average 11-year-old student.'
  }
};

const readabilityScore = text => {
  let totalWords = text.split(' ').length;
  // TODO Find a better way to calculate total sentences
  let totalSentences = text.split('.').length;
  let totalSyllables = syllable(text);

  let score =
    206.835 -
    1.015 * (totalWords / totalSentences) -
    84.6 * (totalSyllables / totalWords);

  if (text.length === 0 || score < -1 || score > 100) score = 0;

  let rangeFloor = Math.ceil(score / 10) * 10;

  return {
    score,
    schoolLevel: scores[rangeFloor].schoolLevel,
    level: scores[rangeFloor].level
  };
};

const Readability = ({ text }) => {
  const readability = readabilityScore(text);
  return (
    <div className="Readability">
      <h2>Readability</h2>
      <div className="ReadabilityItem">
        <b>Readability Score</b>
        <p className="ReadabilityItem-content">
          {Math.round(readability.score)}
        </p>
      </div>
      <div className="ReadabilityItem">
        <b>School Level</b>
        <p className="ReadabilityItem-content">{readability.schoolLevel}</p>
      </div>
      <div className="ReadabilityItem">
        <b>Description</b>
        <p className="ReadabilityItem-content">{readability.level}</p>
      </div>
      <div className="ReadabilityItem">
        <b>Word Count</b>
        <p className="ReadabilityItem-content">
          {text.trim().length ? text.trim().split(' ').length : 0}
        </p>
      </div>
      <p className="Readability-info">
        Using the&nbsp;
        <a href="https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests">
          Flesch–Kincaid
        </a>
        &nbsp;grade level.
      </p>
    </div>
  );
};

export default Readability;
